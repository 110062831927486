import { IMAGE_TYPE_URL } from '@/utils/common';
import format from '@/utils/format';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';
import defaultBanner from '@/assets/images/defaultBanner.png';
import { IBanner } from './Banner';

const BannerCustomizeHeight = ({ data, priority }: IBanner) => {
  const { images } = data?.[0] || {};

  const url = useMemo(
    () =>
      format.convertToS3Link({
        type: IMAGE_TYPE_URL.BANNER,
        objectId: images?.[0]?.object_id,
        filename: images?.[0]?.filename || '',
      }),
    [data, images],
  );
  const [imgSrc, setImgSrc] = useState<string | StaticImport>(url);
  useEffect(() => {
    setImgSrc(url);
  }, [url]);

  const [maxPaddingTop, setMaxPaddingTop] = useState(0);

  const findMaxPaddingTop = (width: number, height: number) => {
    const paddingTopImage = height / width;
    setMaxPaddingTop(prev => (prev < paddingTopImage ? paddingTopImage : prev));
  };

  return (
    <Link
      href={`${data?.[0]?.targetLink}`}
      target="_blank"
      rel="noopener noreferrer nofollow"
      style={{
        display: 'flex',
        position: 'relative',
        height: '0',
        width: '100%',
        paddingTop: (maxPaddingTop * 100).toString() + '%',
      }}
    >
      <Image
        src={imgSrc}
        alt={'banner'}
        fill
        priority={priority}
        style={{ objectFit: 'contain' }}
        quality={60}
        sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 30vw" // className="object-cover"
        onError={() => setImgSrc(defaultBanner)}
        onLoad={({ target }) => {
          const { naturalWidth, naturalHeight } = target as HTMLImageElement;
          findMaxPaddingTop(naturalWidth, naturalHeight);
        }}
      />
    </Link>
  );
};
export default BannerCustomizeHeight;
